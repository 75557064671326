import capi from '../capi/instance';
import { CommonRes, NoParamReq, SystemDictType } from '../../types/index';

// 字典类型种类
type DictType =
  | 'virtualmanType' // 数智人类型
  | 'clothes' // 服装
  | 'pose' // 姿态
  | 'screenType' // 分辨率分类
  | 'resolution' // 分辨率
  | 'background' // 背景
  | 'logo' // logo
  | 'timbre' // 音色
  | 'speed' // 语速
  | 'modelSource' //
  | 'dimension' //
  | 'driverType' //
  | 'emotion' // 情绪
  | 'bitRate' // 码率
  | 'appkey' // 预览appkey
  | 'accesstoken' // 预览accesstoken
  | 'platformType' // 数据来源
  | 'taskType' // 音视频类型
  | 'timbre_category' // 音色分类
  | 'virtualmanTypeV2' // 数智人类型v2
  | 'timbre_audition_text' // 数智人语种
  | 'timbre_language'; // 外语语种

// 通用字段字典接口 req - 参数为空获取全量
export type GetSystemDictReq = { dictTypes: DictType[] } | NoParamReq;

// 通用字段字典接口 res
export type GetSystemDictRes = CommonRes & {
  records: {
    dictType: string;
    systemDicts: SystemDictType[];
  }[];
};

// 通用字段字典接口
export const getSystemDict = async (data: GetSystemDictReq = undefined) => {
  const res = await capi<GetSystemDictReq, GetSystemDictRes>('DescribeSystemDictByType', data);
  return res;
};
